import React from 'react'
import { graphql } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'
import SEO from 'components/SEO'
import MediaPostContent from 'components/Media/Post'

const MediaPost = ({ data, location }) => {
  const { markdownRemark } = data
  return (
    <>
      <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.excerpt} />
      <SimpleReactLightbox>
        {<MediaPostContent data={markdownRemark} pathname={location.pathname} />}
      </SimpleReactLightbox>
    </>
  )
}

export default MediaPost

export const query = graphql`
  query MediaPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        images {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      html
      excerpt
    }
  }
`
